import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal" ]
  static classes = [ "active", "modal" ]
  static outlets = [ "inertia-management", "focus-management" ]

  connect() {
    if (this.#isModalElement(this.element) && this.element.classList.contains(this.activeClass)) {
      this.immobilizeIfPossible()
    }
  }

  disconnect() {
    this.closeCallbacks()
  }

  closeCallbacks() {
    this.mobilizeIfPossible()
    this.restoreFocusIfPossible()
  }

  immobilizeIfPossible() {
    if (this.hasInertiaManagementOutlet) {
      this.inertiaManagementOutlet.immobilize()
    }
  }

  mobilizeIfPossible() {
    if (this.hasInertiaManagementOutlet) {
      this.inertiaManagementOutlet.mobilize()
    }
  }

  restoreFocusIfPossible() {
    if (this.hasFocusManagementOutlet) {
      this.focusManagementOutlet.restoreFocus()
    }
  }

  hideIfSuccess(event) {
    if (event.detail.success) {
      this.hide(event)
    }
  }
  
  show(event) {
    const targetElement = this.#getModalTargetFromEvent(event)

    if (targetElement) {
      this.openModal = targetElement
      targetElement.classList.add(this.activeClass)
      this.immobilizeIfPossible()
    }
  }
  
  hide(event) {
    const targetElement = this.#getModalTargetFromEvent(event) || this.openModal

    if (targetElement) {
      targetElement.classList.remove(this.activeClass)
      this.openModal = null
      this.dispatch("close", { target: targetElement })
      this.closeCallbacks()
    }
  }

  // Private
  #isModalElement(element) {
    return element.classList.contains(this.modalClass)
  }

  #findModalTargetWithID(id) {
    return this.modalTargets.find((target) => target.id === id)
  }

  #hasMultipleModalTargets() {
    return (this.modalTargets && this.modalTargets.length > 1)
  }

  #getModalTargetFromEvent({ params, target }) {
    if (this.#hasMultipleModalTargets()) {
      const targetID = params.id || target.value
      return this.#findModalTargetWithID(targetID)
    }

    return this.modalTarget
  }
}
